import { ArrowOutOfBoxIcon } from '@fingertip/icons'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import { ShareButton } from '../shared/share-button'

type Props = {
  siteSlug: string
  isMember?: boolean
}

export const SharePageButton = ({ siteSlug, isMember }: Props) => {
  const { t } = useTranslation()

  if (isMember) {
    return (
      <Link
        href={`/sites/${siteSlug}/share`}
        title={t('share_page')}
        className="px-3 pb-1.5"
      >
        <ArrowOutOfBoxIcon className="size-6" />
      </Link>
    )
  }

  return (
    <ShareButton className="px-3 pb-1.5">
      <ArrowOutOfBoxIcon className="size-6" />
    </ShareButton>
  )
}
