import { EditBigIcon } from '@fingertip/icons'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

type Props = {
  siteSlug: string
  pageSlug: String
}

export const EditPageButton = ({ siteSlug, pageSlug }: Props) => {
  const { t } = useTranslation()
  return (
    <Link
      href={`/sites/${siteSlug}${pageSlug !== 'index' ? `/${pageSlug}` : ''}`}
      title={t('edit_page')}
      className="px-3 py-1.5"
    >
      <EditBigIcon className="size-6" />
    </Link>
  )
}
