import copy from 'copy-to-clipboard'
import { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { cn } from '@/lib/utils'

type Props = {
  children: ReactNode
  text?: string
  url?: string
  description?: string
  className?: string
  onCopy?: () => void
}

export const ShareButton = ({
  children,
  text,
  url,
  description,
  className,
  onCopy,
}: Props) => {
  const { t } = useTranslation()

  const handleShare = useCallback(async () => {
    onCopy?.()

    let urlToShare = url ?? window.location.href

    // Remove 'preview' query param
    const urlObj = new URL(urlToShare)
    urlObj.searchParams.delete('preview')
    urlToShare = urlObj.toString()

    try {
      await navigator.share({
        title: document.title,
        url: urlToShare,
        text,
      })
    } catch (_err: any) {}

    copy(urlToShare)
    toast.success(description || t('copied_and_ready_to_'))
  }, [description, onCopy, t, text, url])

  return (
    <button
      onClick={() => handleShare()}
      className={cn('cursor-pointer', className)}
      title={t('share')}
    >
      {children}
    </button>
  )
}
