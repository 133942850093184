'use client'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

type Props = {
  gaId: string
}

export const GoogleAnalyticsTracker = ({ gaId }: Props) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    window.gtag('event', 'page_view', {
      send_to: gaId,
    })
  }, [pathname, gaId, searchParams])

  return null
}
