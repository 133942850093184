import { QrCodeIcon } from '@fingertip/icons'
import React, { useCallback, useContext } from 'react'

import { RootStoreContext } from '@/lib/stores/root-store'

export const QrTrigger = () => {
  const {
    publicStore: { setDialogBlockId },
  } = useContext(RootStoreContext)

  const handleClick = useCallback(() => {
    setDialogBlockId('QR')
  }, [setDialogBlockId])

  return (
    <button
      title="QR"
      className="px-3 cursor-pointer py-1.5"
      onClick={handleClick}
    >
      <QrCodeIcon />
    </button>
  )
}
