'use client'
import { PartialMessage } from '@bufbuild/protobuf'
import type { PageThemeSchema } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/page_theme_schema_pb'
import { memo, useMemo } from 'react'

import { getDefaultPageTheme } from '@/lib/data/page-themes'
import { useGoogleFonts } from '@/lib/hooks/use-google-fonts'

type Props = {
  content: PartialMessage<PageThemeSchema> | undefined
}

export const PageFont = memo(({ content: defaultContent }: Props) => {
  const content = getDefaultPageTheme(defaultContent)

  const fontConfigs = useMemo(
    () => [
      {
        family: content?.headingFontFamily || '',
        weights: [content?.headingFontWeight || ''],
      },
      {
        family: content?.bodyFontFamily || '',
        weights: [
          content?.bodyFontWeight || '',
          content?.primaryButtonFontWeight || '',
          content?.secondaryButtonFontWeight || '',
        ],
      },
    ],
    [
      content?.bodyFontFamily,
      content?.bodyFontWeight,
      content?.headingFontFamily,
      content?.headingFontWeight,
      content?.primaryButtonFontWeight,
      content?.secondaryButtonFontWeight,
    ],
  )

  useGoogleFonts(fontConfigs)

  return null
})
