import { ArrowOutOfBoxIcon, SquareBehindSquare2Icon } from '@fingertip/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonDiv } from '@/components/ui/button'
import { cn } from '@/lib/utils/cn'

import { Copy } from '../ui/copy'
import { Share } from '../ui/share'
import { LogoIcon } from './logo-icon'

type Props = {
  value: string
  valuePrefix?: string
  displayValue?: string
  prefix?: string
  onShare?: () => void
  onCopy?: () => void
  description?: string
}

export const ShareField = ({
  value,
  displayValue,
  prefix,
  valuePrefix,
  description,
  onShare,
  onCopy,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="w-full relative">
      <div className="flex w-full max-w-[calc(100vw-36px)] cursor-pointer flex-row sm:max-w-[479px]">
        <div className="flex h-[52px] w-full items-center rounded-2xl border border-border bg-card p-0">
          <div className="pl-4">
            <LogoIcon width={20.8} height={17.3} className="shrink-0" />
          </div>

          <a
            className={cn(
              'block p-4 pl-3 grow overflow-hidden text-ellipsis text-left text-sm underline underline-offset-2 hover:underline',
            )}
            href={value}
            target="_blank"
          >
            {prefix && <span>{prefix}</span>}
            <span className="truncate font-normal">
              {displayValue || value}
            </span>
          </a>

          <Copy content={value} onCopy={onCopy}>
            <ButtonDiv
              variant="muted"
              size="xs"
              className="my-1 mr-1 size-[44px] rounded-[12px]! p-0"
              title={t('copy_0')}
            >
              <SquareBehindSquare2Icon className="size-4 shrink-0" />
            </ButtonDiv>
          </Copy>

          <Share
            value={value}
            valuePrefix={valuePrefix}
            displayValue={displayValue}
            description={description}
            onShare={onShare}
          >
            <ButtonDiv
              variant="muted"
              size="xs"
              className="my-1 mr-1 size-[44px] rounded-[12px]! p-0"
              title={t('share')}
            >
              <ArrowOutOfBoxIcon className="size-4 shrink-0" />
            </ButtonDiv>
          </Share>
        </div>
      </div>
    </div>
  )
}
