'use client'
import { disableQuery, useQuery } from '@connectrpc/connect-query'
import { getSiteMembership } from '@fingertip/creator-proto/gen/fingertip/creator/site_membership/v1/site_membership-SiteMembershipService_connectquery'
import { getUserProfile } from '@fingertip/creator-proto/gen/fingertip/creator/user_profile/v1/user_profile-UserProfileService_connectquery'
import { DotGrid1x3VerticalIcon, LogoStandaloneIcon } from '@fingertip/icons'
import { type Session } from '@supabase/supabase-js'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RatingDialog } from '@/components/rating/rating-dialog'
import { UserButton } from '@/components/user/user-button'
import { useToken } from '@/lib/hooks/use-token'
import { getSupabaseClient } from '@/lib/supabase/supabase-client'

import { EditPageButton } from './edit-page-button'
import { QrDialog } from './qr-dialog'
import { QrTrigger } from './qr-trigger'
import { SharePageButton } from './share-page-button'

type Props = {
  pageSlug: string
  siteSlug: string
  isWhiteLabeled?: boolean
}

export const PageNavBar = ({ siteSlug, pageSlug, isWhiteLabeled }: Props) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const searchParams = useSearchParams()
  const opengraph = searchParams?.get('opengraph')
  const utmSource = String(searchParams?.get('utm_source'))
  const isSource = [
    'apple-wallet',
    'google-wallet',
    'contact',
    'qr-block',
  ].includes(utmSource)
  const [session, setSession] = useState<Session | null>(null)

  const { token, callOptions } = useToken()

  const { data: userData } = useQuery(
    getUserProfile,
    !token ? disableQuery : {},
    {
      callOptions,
    },
  )

  const { data: siteMembership } = useQuery(
    getSiteMembership,
    !token
      ? disableQuery
      : {
          siteSlug,
        },
    {
      callOptions,
    },
  )

  const currentUser = userData?.userProfile

  useEffect(() => {
    const getData = async () => {
      const supabase = getSupabaseClient()

      const {
        data: { session },
      } = await supabase.auth.getSession()

      setSession(session)
    }

    getData()
  }, [])

  if (!!opengraph || isSource) {
    return null
  }

  return (
    <>
      <div className="fixed bottom-4 right-4 z-30">
        <div className="mx-auto w-full">
          <div className="flex justify-center">
            <div className="rounded-full bg-white/30 text-foreground shadow-md backdrop-blur-sm dark:bg-black/30">
              <div className="flex flex-col items-center justify-between">
                {open && (
                  <>
                    <UserButton
                      showChevron={false}
                      currentUser={currentUser}
                      className="px-1 pt-3 pb-2 cursor-pointer"
                      avatarSize={32}
                      hideContactSupport
                    />

                    <SharePageButton
                      siteSlug={siteSlug}
                      isMember={!!siteMembership?.membership}
                    />

                    {!!siteMembership?.membership && (
                      <EditPageButton siteSlug={siteSlug} pageSlug={pageSlug} />
                    )}

                    <QrTrigger />

                    <RatingDialog
                      siteSlug={siteSlug}
                      pageSlug={pageSlug}
                      session={session}
                    />
                  </>
                )}

                <button
                  onClick={() => setOpen(!open)}
                  type="button"
                  className="flex cursor-pointer size-12 items-center justify-center text-center"
                  aria-label={open ? t('close_menu') : t('open_menu')}
                >
                  {isWhiteLabeled ? (
                    <DotGrid1x3VerticalIcon className="size-6" />
                  ) : (
                    <LogoStandaloneIcon height={20} width={24} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <QrDialog siteSlug={siteSlug} pageSlug={pageSlug} utmSource={utmSource} />
    </>
  )
}
