'use client'
import { usePathname, useSearchParams } from 'next/navigation'
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'

import { useEvents } from '@/lib/hooks/use-events'
import { PHEventName } from '@/lib/posthog/events'
import { getSupabaseClient } from '@/lib/supabase/supabase-client'

type Props = {
  data: {
    page_slug?: string
    page_id?: string
    site_slug?: string
    site_id?: string
  }
}

export const PageView = ({ data }: Props) => {
  const postHog = usePostHog()
  const events = useEvents()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    const getData = async () => {
      const supabase = getSupabaseClient()

      const {
        data: { session },
      } = await supabase.auth.getSession()

      if (session?.user) {
        postHog.identify(session.user.id, {
          email: session?.user.email,
        })
      }

      let url = window.origin + pathname
      if (searchParams && searchParams.toString()) {
        url = url + `?${searchParams.toString()}`
      }

      postHog.capture(PHEventName.PAGE_VIEW, {
        $current_url: url,
        ...data,
      })

      events.capture(PHEventName.PAGE_VIEW, {
        userId: session?.user.id,
        email: session?.user.email,
        url,
        pathname: pathname || undefined,
        pageSlug: data?.page_slug,
        pageId: data?.page_id,
        siteSlug: data?.site_slug,
        siteId: data?.site_id,
      })
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams])

  return null
}
