import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { QrHtmlInnerElement } from '@/components/qr/qr-html-inner-element'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { env } from '@/env'
import { RootStoreContext } from '@/lib/stores/root-store'

import { ShareField } from '../shared/share-field'

type Props = {
  siteSlug: string
  pageSlug: string
  utmSource: string
}

export const QrDialog = observer(({ siteSlug, pageSlug, utmSource }: Props) => {
  const { t } = useTranslation()
  const {
    publicStore: { setDialogBlockId, dialogBlockId },
  } = useContext(RootStoreContext)
  const [open, setOpen] = useState(utmSource === 'manifest')
  const isOpen = dialogBlockId === 'QR' || open

  const data = `${env.NEXT_PUBLIC_SITE_URL}/${siteSlug}${pageSlug !== 'index' ? `/${pageSlug}` : ''}?utm_source=qr-block`

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open)

      if (!open) {
        setDialogBlockId(null)
      }
    },
    [setDialogBlockId],
  )

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent>
        <DialogHeader className="w-full pb-2 pt-4">
          <DialogTitle className="mt-6 text-center">
            {t('scan_to_open_page_on_your_phone')}
          </DialogTitle>
        </DialogHeader>

        <div className="my-5 flex w-full justify-center">
          <QrHtmlInnerElement
            data={data}
            theme={{
              id: 'custom',
              key: 'Custom',
              logoColor: 'currentColor',
              foregroundColor: 'currentColor',
              backgroundColor: 'currentColor',
              borderColor: 'currentColor',
            }}
            className="aspect-square w-full"
            style={{
              maxHeight: 200,
              maxWidth: 200,
            }}
            correction="H"
          />
        </div>

        <ShareField
          description={t('copied_fingertip_url_ready_to_share')}
          prefix="fingertip.com/"
          displayValue={`${siteSlug}${pageSlug !== 'index' ? `/${pageSlug}` : ''}`}
          value={data}
        />
      </DialogContent>
    </Dialog>
  )
})
