'use client'

import { useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { DEFAULT_WIDTH, MAX_WIDTH } from '../blocks/shared/constants'

export const ScaleFactor = () => {
  const [scaleFactor, setScaleFactor] = useState(() => {
    return (
      Math.min(window?.innerWidth || DEFAULT_WIDTH, MAX_WIDTH) / DEFAULT_WIDTH
    )
  })
  const { width = DEFAULT_WIDTH } = useWindowSize()

  useEffect(() => {
    setScaleFactor(Math.min(width, MAX_WIDTH) / DEFAULT_WIDTH)
  }, [width, setScaleFactor])

  return (
    <>
      <style>
        {`
      :root {
        --scale-factor: ${(scaleFactor || 1).toFixed(7)};
      }
      `}
      </style>
    </>
  )
}
